<template>
  <form
    class="form-builder__tag"
    :class="schema.styleClasses || ''"
    @submit="handleSubmit"
  >
    <div class="row items-center full-width relative">
      <div :class="`${fieldClass} ${$q.dark.isActive ? 'q-field--dark text-white' : ''} ${schema.disabled ? 'q-field--disabled' : ''}`">
        <div class="q-field__inner relative-position col self-stretch column justify-center">
          <div :class="`q-field__control relative-position row no-wrap ${bg}`">
            <div class="q-field__control-container col relative-position row no-wrap q-anchor--skip">
              <div :class="`q-field__native row items-center full-width`">
                <span />

                <div class="row">
                  <q-badge
                    v-for="(val, i) in schema.value"
                    :key="`${getID(val)}:${i}`"
                    color="blue"
                    class="q-mr-xs q-mb-xs q-py-xs row items-center"
                  >
                    <span v-if="schema.customTag">
                      {{ schema.customTag(val) }}
                    </span>

                    <span v-else>
                      {{ val }}
                    </span>

                    <q-icon
                      name="close"
                      class="q-ml-sm"
                      @click="handleRemove(val)"
                    />
                  </q-badge>
                </div>

                <input
                  type="text"
                  class="tag-field col"
                  :value="search"
                  :class="textColor"
                  :disabled="!!schema.disabled"
                  @focus="handleFocus"
                  @focusout="handleFocusOut"
                  @input="handleInput"
                >
              </div>

              <div :class="`q-field__label ${textColor} no-pointer-events absolute ellipsis`">
                {{ `${schema.label} ${schema.required ? '*' : ''}` }}
              </div>
            </div>
          </div>
          <div v-if=schema.hint class="q-ml-md" style="color: grey">
            {{ schema.hint}}
          </div>

        </div>

        <div
          v-if="isFocused"
          ref="scrollTargetRef"
          style="max-height: 200px; overflow: auto; position: absolute; z-index: 2; left: 0; right: 0; top: 56px;"
        >
          <q-infinite-scroll
            ref="scroll"
            @load="onLoad"
            :disable="page >= totalPages || isLoading"
            :offset="250"
            :scroll-target="$refs.scrollTargetRef"
          >
            <q-list
              :class="$q.dark.isActive ? 'bg-dark' : 'bg-white'"
              bordered
              separator
            >
              <q-item
                v-for="item in options"
                :key="item.id"
                v-ripple
                clickable
                @click="handleClick(item)"
              >
                <q-item-section>
                  <span v-if="schema.customListItem">
                    {{ schema.customListItem(item) }}
                  </span>

                  <span v-else>
                    {{ item }}
                  </span>
                </q-item-section>
              </q-item>
            </q-list>

            <div
              v-if="isLoading"
              class="row justify-center q-py-md"
              :class="$q.dark.isActive ? 'bg-dark' : 'bg-white'"
            >
              <q-spinner-dots color="primary" size="40px" />
            </div>
          </q-infinite-scroll>
        </div>
      </div>
    </div>
  </form>
</template>

<script>
export default {
  name: 'Tag',
  props: {
    schema: {
      type: Object,
      default () {
        return {}
      }
    }
  },
  data () {
    return {
      isFocused: false,
      search: '',
      page: 0,
      totalPages: 1,
      isLoading: false,
      values: []
    }
  },
  computed: {
    options () {
      return this.schema.onScroll
        ? this.values
        : this.schema.options
    },
    bg () {
      return this.isFocused
        ? 'bg-teal text-white'
        : ''
    },
    fieldClass () {
      return this.isFocused || this.schema.value
        ? 'q-field col row no-wrap items-start full-width q-select q-field--auto-height q-select--without-input q-select--without-chips q-select--single q-field--standout q-field--focused q-field--float q-field--labeled q-validation-component'
        : 'q-field col row no-wrap items-start full-width q-select q-field--auto-height q-select--without-input q-select--without-chips q-select--single q-field--standout q-field--labeled q-validation-component'
    },
    textColor () {
      return this.isFocused
        ? 'text-white'
        : ''
    }
  },
  methods: {
    getID (value) {
      if (value && typeof value === 'object') {
        return value.id ?? value.field ?? 'undefined'
      }

      return value
    },
    handleFocus () {
      this.isFocused = true

      if (this.values.length <= 0) {
        this.onLoad()
      }
    },
    handleFocusOut () {
      setTimeout(() => {
        this.isFocused = false
      }, 500)
    },
    handleInput (e) {
      this.search = e.target.value
      this.page = 0
      this.totalPages = 1
      this.onLoad()
    },
    handleSubmit (e) {
      e.preventDefault()

      if (typeof this.schema.onSubmit === 'function') {
        this.schema.onSubmit(this.search)
        this.search = ''
      }
    },
    getItemsData (data) {
      if (Array.isArray(data)) {
        return { items: data, totalPages: 1 }
      }

      return data
    },
    onLoad () {
      if (typeof this.schema.onScroll !== 'function' || this.page >= this.totalPages || this.isLoading) {
        return
      }

      this.page += 1
      this.isLoading = true

      let search = (this.search || '').replace(/  +/g, ' ').trim()

      if (search && search[search.length - 1] !== '*' && search[search.length - 2] !== ':' && !search.includes('%')) {
        search += search[search.length - 1] === ':'
          ? '*'
          : ':*'
      }

      this.schema.onScroll(search, this.page)
        .then((data) => {
          if (!data || typeof data !== 'object') {
            return Promise.reject(new Error(`Format of received data in field is wrong! Response must be object or array!`))
          }

          const { items, totalPages } = this.getItemsData(data)

          if (this.page <= 1) {
            this.values = Object.freeze([...items])
            this.$refs.scroll.reset()
          } else {
            this.values = Object.freeze([
              ...this.values,
              ...items
            ])
          }

          this.totalPages = totalPages
          return data
        })
        .catch(e => {
          this.page -= 1
          return e
        })
        .finally(() => {
          this.isLoading = false
        })
    },
    handleRemove (option) {
      if (this.schema.disabled) {
        return
      }

      typeof this.schema.onRemove === 'function' && this.schema.onRemove(option)
    },
    handleClick (option) {
      typeof this.schema.onAdd === 'function' && this.schema.onAdd(option)
    }
  }
}
</script>

<style>
.tag-field {
  background: inherit;
  border: none;
  outline: none;
}
</style>
